import React, { useState, useEffect, useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebaseConfig";
import { useNavigate, useLocation } from "react-router-dom";
import {
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";
import { getDatabase, get, ref, set } from "firebase/database";
import { UserContext } from "../context/UserContext";
import "./LoginPage.css";

const sanitizeEmail = (email) => {
  return email.replace('@', '%40').replace('.', '%2E');
}

function LoginPage() {
  const [authUser] = useAuthState(auth);
  const { user } = useContext(UserContext);

  const db = getDatabase();

  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;

  const [email, setEmail] = useState("");

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState("");

  const [infoMsg, setInfoMsg] = useState("");

  const [initialLoading, setInitialLoading] = useState(false);
  const [initialError, setInitialError] = useState("");

  useEffect(() => {
    if (authUser) {
      const createNewUserIfNeeded = async () => {
        const userRef = ref(db, `/users/${authUser.uid}`);
        const userSnapshot = await get(userRef);
        if (!userSnapshot.exists()) {
          await set(userRef, {
            email: sanitizeEmail(authUser.email),
            user_id: authUser.uid,
          });
        }
        navigate("/");
      };
      createNewUserIfNeeded();
    } else {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        let email = localStorage.getItem("email");
        if (!email) {
          email = window.prompt("Please provide your email");
        }
        setInitialLoading(true);
        signInWithEmailLink(
          auth,
          localStorage.getItem("email"),
          window.location.href
        )
          .then((result) => {
            console.log(result.user);
            localStorage.removeItem("email");
            setInitialLoading(false);
            setInitialError("");
            navigate("/");
          })
          .catch((err) => {
            setInitialLoading(false);
            setInitialError(err.message);
            navigate("/login");
          });
      } else {
        console.log("enter email and sign in");
      }
    }
  }, [user, search, navigate, authUser, db]);

  const handleLogin = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    sendSignInLinkToEmail(auth, email, {
      url: `${window.location.protocol}//${window.location.host}/login`,
      handleCodeInApp: true,
    })
      .then(() => {
        localStorage.setItem("email", email);
        setLoginLoading(false);
        setLoginError("");
        setInfoMsg("We have sent you an email with a link to sign in");
      })
      .catch((err) => {
        setLoginLoading(false);
        setLoginError(err.message);
      });
  };

  return (
    <div className="login-page">
      <nav className="login-page-navbar">
      </nav>
      <div className="login-page-container">
        {initialLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {initialError !== "" ? (
              <div className="error-msg">{initialError}</div>
            ) : (
              <>
                {user ? (
                  <div>Please wait...</div>
                ) : (
                  <>
                    <h1 className="login-page-header">
                      Access Cornerpiece<span className="login-page-header-reg">&reg;</span>
                    </h1>
                    <form className="login-page-form" onSubmit={handleLogin}>
                      <p>Already Registered? Enter your email below to login.</p>
                      <label>EMAIL</label>
                      <input
                        type={"email"}
                        required
                        placeholder="hello@reallygreatsite.com"
                        className="form-control"
                        value={email || ""}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button
                        type="submit"
                        className="login-page-form-submit-btn"
                      >
                        {loginLoading ? (
                          <span>Logging you in</span>
                        ) : (
                          <span>SUBMIT</span>
                        )}
                      </button>

                      {loginError !== "" && (
                        <div className="error-msg">{loginError}</div>
                      )}

                      {infoMsg !== "" && (
                        <div className="info-msg">{infoMsg}</div>
                      )}
                    </form>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
      <div className="page-footer">
        <span><a href="https://enticedge.com">enticedge.com</a> | <a href="tel:+13127259307">01 (312) 725 9307</a> | <a href="mailto:info@enticedge.com">info@enticedge.com</a></span>
      </div>
    </div>
  );
}

export default LoginPage;
