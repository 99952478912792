import React, { useContext, useState, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebaseConfig";
import { db } from "../firebase/firebaseConfig";
import { UserContext } from "../context/UserContext";
import { SuperAdminContext } from "../context/SuperAdminContext";
import { CornerpieceContext } from "../context/CornerpieceContext";
import { get, ref } from "firebase/database";
import { Navigate } from "react-router-dom";
import CoreComponents from "../components/CoreComponents";
import TargetedMessaging from "./TargetedMessaging";
import GoalsAndStrategies from "./GoalsAndStrategies";
import NavBar from "../components/NavBar";
import "./HomePage.css";

function HomePage() {
  const user = useContext(UserContext);
  const cornerpieceUrl = useContext(CornerpieceContext);
  const superAdmins = useContext(SuperAdminContext);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [access, setAccess] = useState(null);

  useEffect(() => {
    const fetchSuperAdminRole = async () => {
      try {
        const superAdminArray = Object.keys(superAdmins || {});
        setIsSuperAdmin(superAdminArray.includes(user.user_id));
      } catch (error) {
        setIsSuperAdmin(false);
      }
    };
    fetchSuperAdminRole();
  }, [user, superAdmins]);

  useEffect(() => {
    const verifyUserAccess = async () => {
      try {
        const userRef = ref(db, `/${cornerpieceUrl}/user_access/${user.id}`);
        const snapshot = await get(userRef);
        if (snapshot.exists() || isSuperAdmin) {
          setAccess(true);
        } else {
          setAccess(false);
          if (!user) {
            return <Navigate to="/login" />;
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
  
    verifyUserAccess();
  }, [user]);

  const [authUser, loading, error] = useAuthState(auth);
  var [selectedPage, setSelectedPage] = useState(0);

  const wrapperSetSelectedPage = (val) => {
    setSelectedPage(val);
  }

  if (error) {
    console.log(error);
  }

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        console.log("successfully logged out");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="HomePage">
      {!user ? (
        <div>Loading...</div>
      ) : (
        <div className="page-container">
          {authUser ? (
            <>
              <NavBar selectedPageSetter={wrapperSetSelectedPage}/>
              <div className="content-container">
                {selectedPage == 0 && (<CoreComponents />)}
                {selectedPage == 1 && (<TargetedMessaging />)}
                {selectedPage == 2 && (<GoalsAndStrategies />)}
                <div className="page-footer">
                  <span><a href="https://enticedge.com">enticedge.com</a> | <a href="tel:+13127259307">01 (312) 725 9307</a> | <a href="mailto:info@enticedge.com">info@enticedge.com</a></span>
                </div>
                <div className="page-footer-mobile">
                  <a href="https://enticedge.com">enticedge.com</a>
                  <a href="tel:+13127259307">01 (312) 725 9307</a>
                  <a href="mailto:info@enticedge.com">info@enticedge.com</a>
                </div>
              </div>
            </>
          ) : (
            <Navigate to="/login" />
          )}
        </div>)}
    </div>
  );
}

export default HomePage;
