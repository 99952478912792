import missionlogo from "../images/missionLogo.svg";

function Mission(props) {
  let title = "Mission";
  let mission = props.mission;
  return (
    <div className="Mission">
      <div className="core-components-grid-logo-container">
        <img src={missionlogo} className="core-components-grid-logo" alt="Mission logo" />
      </div>
      <div className="core-components-grid-info">
        <h3>{title}</h3>
        <p>{mission}</p>
      </div>
    </div>
  );
}

export default Mission;
