import React, { useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { ref, set } from "firebase/database";
import "./SuperAdminPageEdit.css";

function SuperAdminPageEdit({
  cornerpieceKey,
  cornerpieceDetails,
  handleClose,
}) {
  const [newName, setNewName] = useState(cornerpieceDetails.name);
  const [newClientName, setNewClientName] = useState(cornerpieceDetails.client_name);

  const handleSaveClick = () => {
    const cornerpieceRef = ref(db, `cornerpieces/${cornerpieceKey}`);
    set(cornerpieceRef, {
      url: cornerpieceDetails.url,
      name: newName,
      client_name: newClientName,
    });
    handleClose();
  };

  return (
    <div className="super-admin-page-edit-container">
      <div className="super-admin-edit-input-container">
        <input
          type="text"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder="Edit Cornerpiece Name"
        />
        <input
          type="text"
          value={newClientName}
          onChange={(e) => setNewClientName(e.target.value)}
          placeholder="Edit Client Name"
        />
      </div>
      <div className="super-admin-edit-buttons-wrapper">
        <button className="superadmin-edit-btn submit-edit-btn" onClick={handleSaveClick}>
          Save
        </button>
        <button className="superadmin-edit-btn close-edit-btn" onClick={handleClose}>
          Cancel
        </button>
      </div>
    </div>
  );
}

export default SuperAdminPageEdit;
