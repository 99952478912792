import "./Goals.css";
import overarchinggoalslogo from "../images/overarchinggoalslogo.svg";
import marketaccelerationgoallogo from "../images/marketaccelerationgoallogo.svg";

function OverarchingGoals(props) {
  console.log(props);
  let is_overarching = props.goalsType === 'overarching';
  let title = is_overarching ? "Overarching Goals" : "Market Acceleration Goals";
  let goals = props.goals.map((item, i) => <li key={i}>{item}</li>);
  return (
    <div className="goals-container">
      <div className="goals-heading-container">
        <div className="goals-image-container">
          <img
            src={is_overarching ? overarchinggoalslogo : marketaccelerationgoallogo}
            className="goals-image"
            alt={is_overarching ? "Overarching Goals Icon" : "Market Acceleration Goals Icon"}
          />
        </div>
        <h3 className="goals-heading">{title}</h3>
      </div>
      <div className="goals-list-container">
        <ul>{goals}</ul>
      </div>
    </div>
  );
}

export default OverarchingGoals;
