import { useState } from "react";
import keyMessagingDropdown from "../images/keyMessagingDropdown.svg";
import "./KeyMessaging.css";

function KeyMessaging(props) {
  const [keyMessagingDropdownOpen, setkeyMessagingDropdownOpen] = useState({});

  const toggle = (key) => {
    setkeyMessagingDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <div className="KeyMessaging">
      <h2>Key Messaging for {props.title}</h2>
      <table className="key-messaging-table">
        <thead>
          <tr>
            <th className="painpoint-header">Pain Point</th>
            <th className="valueprop-header">Value Proposition</th>
          </tr>
        </thead>
        <tbody>
          {props.valueProps.map((val, key) => {
            return (
              <tr key={key}>
                <td className="painpoint">{val.pain_point}</td>
                <td className="valueprop">{val.value_prop}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="key-messaging-table-mobile">
        {props.valueProps.map((val, key) => {
          return (
            <div key={key} className="key-messaging-details-mobile">
              <div
                className="painpoint-toggle-mobile"
                onClick={() => toggle(key)}
              >
                <span className="painpoint-mobile">{val.pain_point}</span>
                <img
                  src={keyMessagingDropdown}
                  className={`key-messaging-dropdown-icon ${
                    keyMessagingDropdownOpen[key] ? "flipped" : ""
                  }`}
                  alt="Key Messaging Dropdown Icon"
                />
              </div>
              <div
                className={`key-messaging-mobile ${
                  keyMessagingDropdownOpen[key] ? "active" : ""
                }`}
              >
                <p className="valueprop-mobile">{val.value_prop}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default KeyMessaging;
