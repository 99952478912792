import { useContext, useEffect, useState } from "react";
import MoreAbout from "../components/MoreAbout";
import KeyMessaging from "../components/KeyMessaging";
import UserPersonas from "../components/UserPersonas";
import userPersonaDropIcon from "../images/userPersonaDropIcon.svg";
import { CornerpieceContext } from "../context/CornerpieceContext";
import { off, onValue, ref } from "firebase/database";
import { db } from "../firebase/firebaseConfig";
import Loading from "../components/Loading";
import Error from "../components/Error";
import "./TargetedMessaging.css";

function TargetedMessaging() {
  const cornerpieceUrl = useContext(CornerpieceContext);
  const [targetedMessaging, setTargetedMessaging] = useState(null);
  const [loading, setLoading] = useState(true);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownIndexSelected, setDropdownIndexSelected] = useState(0);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleDropdownSelect = (index) => {
    setDropdownIndexSelected(index);
    setDropdownOpen(false);
  };

  useEffect(() => {
    console.log(cornerpieceUrl);
    const targetedMessagingRef = ref(
      db,
      `${cornerpieceUrl}/targeted_messaging`
    );
    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        setTargetedMessaging(snapshot.val());
      } else {
        console.log("No data available");
      }
      setLoading(false);
    };
    onValue(targetedMessagingRef, handleDataChange, { onlyOnce: false });
    return () => off(targetedMessagingRef, "value", handleDataChange);
  }, [cornerpieceUrl, setTargetedMessaging]);

  if (loading) {
    return <Loading />;
  }
  if (!targetedMessaging || !targetedMessaging.hasOwnProperty("personas")) {
    return <Error />;
  }
  const personas = targetedMessaging["personas"];
  for (let i in personas) {
    if (
      !personas[i].hasOwnProperty("name") ||
      !personas[i].hasOwnProperty("title") ||
      !personas[i].hasOwnProperty("quote") ||
      !personas[i].hasOwnProperty("value_props") ||
      !personas[i].hasOwnProperty("bio") ||
      !personas[i].hasOwnProperty("motivations") ||
      !personas[i].hasOwnProperty("frustrations")
    ) {
      return <Error />;
    }
  }
  const selectedPersona = personas[dropdownIndexSelected];
  return (
    <div className="TargetedMessaging">
      <h1 className="page-title">Targeted Messaging</h1>
      <div className="user-persona-dropdown">
        <div className="user-persona-dropdown-container">
          <div className="user-persona-dropdown-main" onClick={toggle}>
            <h2>
              {selectedPersona.name} - {selectedPersona.title}
            </h2>
            <img
              src={userPersonaDropIcon}
              className="user-persona-dropdown-icon"
              alt="User Persona Dropdown Icon"
            />
          </div>
        </div>
      </div>
      {dropdownOpen && (
        <div className="user-persona-dropdown-items">
          <ul>
            {personas.map((persona, index) => (
              <li
                key={index}
                onClick={(e) => handleDropdownSelect(index)}
                className={
                  index === dropdownIndexSelected
                    ? "user-persona-dropdown-items-selected"
                    : ""
                }
              >
                {persona.name} - {persona.title}
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="messaging-container">
        <UserPersonas
          name={selectedPersona.name}
          title={selectedPersona.title}
          quote={selectedPersona.quote}
        />
        <KeyMessaging
          title={selectedPersona.title}
          valueProps={selectedPersona.value_props}
        />
        <MoreAbout persona={selectedPersona} />
      </div>
    </div>
  );
}

export default TargetedMessaging;
