import userPersonasLogo from "../images/userpersonaslogo.svg";
import "./UserPersonas.css";

function UserPersonas(props) {
  return (
    <div className="UserPersonas">
      <div className="userpersonas-container">
        <div className="userpersonas-image">
          <img
            src={userPersonasLogo}
            className="UserPersonas-Logo"
            alt="UserPersonas logo"
          />
        </div>
        <div className="userpersonas-text">
          <h3>{props.name}</h3>
          <h4>{props.title}</h4>
          <p>{props.quote}</p>
        </div>
      </div>
    </div>
  );
}

export default UserPersonas;
