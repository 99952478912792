import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { SuperAdminContext } from "../context/SuperAdminContext";
import { get, ref } from "firebase/database";
import { db } from "../firebase/firebaseConfig";

const SuperAdminRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  var [superAdmins, setSuperAdmins] = useState([]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    const fetchSuperAdmins = async () => {
      const snapshot = await get(ref(db, '/superAdmins'));
      const newSuperAdmins = snapshot.val();
      setSuperAdmins(Object.keys(newSuperAdmins));
      
      if (user && !(user.user_id in newSuperAdmins)) {
        navigate("/", { state: { from: location } });
      }
    };

    fetchSuperAdmins();
  }, [user, navigate, location, superAdmins, setSuperAdmins]);

return (
  <UserContext.Provider value={{ user }}>
    <SuperAdminContext.Provider value={superAdmins}>
      {children}
    </SuperAdminContext.Provider>
  </UserContext.Provider>
);
};

export default SuperAdminRoute;
