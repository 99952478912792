import React, { useContext, useState } from "react";
import AdminPageAddUsers from "../components/AdminPageAddUsers";
import AdminPageViewAllUsers from "../components/AdminPageViewAllUsers";
import adminUsersIcon from "../images/adminUsersIcon.svg";
import "./AdminPage.css";
import { CornerpieceContext } from "../context/CornerpieceContext";
import { useNavigate } from "react-router";

function AdminPage() {
  const [showAddForm, setShowAddForm] = useState(false);
  const cornerpieceUrl = useContext(CornerpieceContext);
  const navigate = useNavigate();

  const handleAddButtonClick = () => {
    setShowAddForm(true);
  };
  const handleCloseForm = () => {
    setShowAddForm(false);
  };

  return (
    <div className="admin-page">
      <div className="admin-page-container">
        <div className="admin-page-header">
          <h1>Admin Controls</h1>
          <button onClick={e => { navigate(`/${cornerpieceUrl}`); }} className="admin-return-buttom">
            Return to Cornerpiece
          </button>
        </div>
        <div className="admin-page-container-header">
          <img src={adminUsersIcon} alt="admin-users-icon" />
          <h2>Users</h2>
        </div>
        <div className="admin-page-users-container">
          <AdminPageAddUsers
            showAddForm={showAddForm}
            handleCloseForm={handleCloseForm}
          />
          <AdminPageViewAllUsers />
        </div>
        <div className="admin-page-container-footer">
          <button
            className="admin-page-add-button"
            onClick={handleAddButtonClick}
          >
            + Add New User
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
