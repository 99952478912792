import React, { useContext, useEffect, useState } from "react";
import { CornerpieceContext } from "../context/CornerpieceContext";
import { db } from "../firebase/firebaseConfig";
import { onValue, ref, set, off, remove } from "firebase/database";
import deleteCornerpiece from "../images/deleteCornerpiece.svg";
import "./AdminPageViewAllUsers.css";

const unsanitizeEmail = (email) => {
  return email.replace('%40', '@').replace('%2E','.');
}

function AdminPageViewAllUsers() {
  const cornerpiece = useContext(CornerpieceContext);
  const [users, setUsers] = useState({});

  useEffect(() => {
    const usersRef = ref(db, `/${cornerpiece}/user_access`);
    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        setUsers(snapshot.val());
      } else {
        console.log("No data available");
      }
    };
    onValue(usersRef, handleDataChange, { onlyOnce: false });
    return () => off(usersRef, "value", handleDataChange);
  }, [cornerpiece]);

  const updateUserRole = (newValue, key) => {
    const userRef = ref(db, `/${cornerpiece}/user_access/${key}`);
    if (newValue === 'admin' || newValue === 'member') {
      set(userRef, newValue);
    }
  }

  const handleDeleteClick = async (key) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmed) {
      const userRef = ref(db, `/${cornerpiece}/user_access/${key}`);
      try {
        await remove(userRef);
      } catch (error) {
        console.error("Error removing cornerpiece member: ", error);
      }
    }
  };

  return (
    <div className="admin-page-view-container">
      {users &&
        Object.entries(users).map(([key, value]) => (
          <div className="admin-page-view-users" key={key}>
            <div className="user-content">
              <div className="user-info">
                <h2 className="user-info-email">{unsanitizeEmail(key)}</h2>
              </div>
              <div className="admin-button-container">
                <select value={value} onChange={e => (updateUserRole(e.target.value, key))}>
                  <option value="admin">Admin</option>
                  <option value="member">Member</option>
                </select>
                <button className="delete-button">
                  <img
                    className="delete-image"
                    src={deleteCornerpiece}
                    alt="deleteCornerpieceUser"
                    onClick={e => handleDeleteClick(key)}
                  />
                </button>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}

export default AdminPageViewAllUsers;
