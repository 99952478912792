import React from "react";
import { UserProvider } from "./context/UserContext";
import { SuperAdminProvider } from "./context/SuperAdminContext";
import { CornerpieceProvider } from "./context/CornerpieceContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import HomePage from "./pages/HomePage";
import SelectCornerpiece from "./pages/SelectCornerpiece";
import SuperAdminRoute from "./private-routes/SuperAdminRoute";
import SuperAdminPage from "./pages/SuperAdminPage";
import AdminRoute from "./private-routes/AdminRoute";
import AdminPage from "./pages/AdminPage";

function App() {
  return (
    <Router>
      <UserProvider>
        <SuperAdminProvider>
          <CornerpieceProvider>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<SelectCornerpiece />} />
              <Route
                path="/superadmin"
                element={
                  <SuperAdminRoute>
                    <SuperAdminPage />
                  </SuperAdminRoute>
                }
              />
              <Route path="/:cornerpieceId" element={<HomePage />} />
              <Route
                path="/:cornerpieceId/admin"
                element={
                  <AdminRoute>
                    <AdminPage />
                  </AdminRoute>
                }
              />
            </Routes>
          </CornerpieceProvider>
        </SuperAdminProvider>
      </UserProvider>
    </Router>
  );
}
export default App;
