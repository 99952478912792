import React, { useState } from "react";
import NavBar from "../components/NavBar";
import SuperAdminPageAdd from "../components/SuperAdminPageAdd";
import SuperAdminPageViewAll from "../components/SuperAdminPageViewAll";
import SuperAdminPageSearch from "../components/SuperAdminPageSearch";
import superAdminAccountsIcon from "../images/superAdminAccountsIcon.svg";
import "./SuperAdminPage.css";

function SuperAdminPage() {
  const [search, setSearch] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);

  const handleAddButtonClick = () => {
    setShowAddForm(true);
  };
  const handleCloseForm = () => {
    setShowAddForm(false);
  };

  return (
    <div className="super-admin-page">
      {/* <NavBar /> */}
      <div className="super-admin-page-container">
        <div className="super-admin-page-container-header">
          <div className="super-admin-page-container-header-title">
            <img
              className="header-image"
              src={superAdminAccountsIcon}
              alt="super admin accounts icon"
            />
            <h1>Cornerpiece Accounts</h1>
          </div>
          <SuperAdminPageSearch setSearch={setSearch} />
        </div>
        <SuperAdminPageAdd
          showAddForm={showAddForm}
          handleCloseForm={handleCloseForm}
        />
        <SuperAdminPageViewAll search={search} />
        <div className="super-admin-page-container-footer">
          <button
            className="super-admin-page-add-button"
            onClick={handleAddButtonClick}
          >
            + Add New Cornerpiece
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuperAdminPage;
