import "./CoreStrategies.css";
import corestrategieslogo from "../images/corestrategieslogo.svg";

function CoreStrategies(props) {
  let title = "Core Strategies";
  let corestrategies = props.coreStrategies.map((item, i) => <li key={i}>{item}</li>);
  return (
    <div className="CoreStrategies">
      <div className="corestrategies-container">
        <div className="corestrategies-image">
          <img
            src={corestrategieslogo}
            className="Core-Strategies-Logo"
            alt="Core Strategies logo"
          />
        </div>
        <h1>{title}</h1>
      </div>
      <div className="corestrategies-list">
        <ol>{corestrategies}</ol>
      </div>
    </div>
  );
}

export default CoreStrategies;
