import "./Loading.css";

function Loading() {
  return (
    <div className="Loading">
      <div className="loading-container">
        <h1 className="loading-title">Loading...</h1>
        <h2 className="loading-subtitle">One moment please.</h2>
      </div>
    </div>
  );
}

export default Loading;
