import valuesLogo from "../images/valuesLogo.svg";

function Values(props) {
  let title = "Values";
  // let value = 
  //   "Clarity with impact | Innovation and creativity at all levels | Hands-on, team-forward approach"
  // ;
  let value = props.values;
  
  return (
    <div className="Values">
      <div className="core-components-grid-logo-container">
        <img src={valuesLogo} className="core-components-grid-logo" alt="Values logo" />
      </div>
      <div className="core-components-grid-info">
      <h3>{title}</h3>
      <p>{value}</p>
      </div>
    </div>
  );
}

export default Values;
