import "./KeyValueProps.css";

function KeyValueProps(props) {
  let title = "Key Differentiators";
  let subtitle =
    "What gives your company its authenticity and competitive edge.";
  // let items = [
  //   "Gamifies staying healthy, making it fun for improved results.",
  //   "Leverages teamwork and social accountability to help individuals find consistency.",
  //   "All of our content is under 20 minutes long, making it more digestible.",
  //   "Benefits both physical and mental health for holistic health impact.",
  //   "Connects users to friends and a broader community for encouragement.",
  //   "All inclusive content.",
  // ];
  // let keyvalueprops = items.map((item, i) => <li key={i}>{item}</li>);
  console.log(props.keyDifferentiators);
  let keyvalueprops = props.keyDifferentiators.map((item, i) => <li key={i}>{item}</li>);

  return (
    <div className="KeyValueProps">
      <h1 className="KeyValueProps-title">{title}</h1>
      <h2>{subtitle}</h2>
      <ol>{keyvalueprops}</ol>
    </div>
  );
}

export default KeyValueProps;
