import React from "react";
import Goals from "../components/Goals";
import "./GoalsAndStrategies.css";
import CoreStrategies from "../components/CoreStrategies";
import KeyPerformanceIndicators from "../components/KeyPerformanceIndicators";
import Loading from "../components/Loading";
import Error from "../components/Error";
import { useContext, useEffect, useState } from "react";
import { CornerpieceContext } from "../context/CornerpieceContext";
import { off, onValue, ref } from "firebase/database";
import { db } from "../firebase/firebaseConfig";

function GoalsAndStrategies() {
  const cornerpieceUrl = useContext(CornerpieceContext);
  const [goalsAndStrategies, setGoalsAndStrategies] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    console.log(cornerpieceUrl);
    const goalsAndStrategiesRef = ref(db, `${cornerpieceUrl}/goals_and_strategies`);
    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        setGoalsAndStrategies(snapshot.val());
      } else {
        console.log("No data available");
      }
      setLoading(false);
    };
    onValue(goalsAndStrategiesRef, handleDataChange, { onlyOnce: false });
    return () => off(goalsAndStrategiesRef, "value", handleDataChange);
  }, [cornerpieceUrl, setGoalsAndStrategies]);

  if (loading) {
    return (
      <Loading />
    );
  }
  if (!goalsAndStrategies ||
      !goalsAndStrategies.hasOwnProperty('core_strategies') ||
      !goalsAndStrategies.hasOwnProperty('overarching_goals') ||
      !goalsAndStrategies.hasOwnProperty('market_accel_goals') ||
      !goalsAndStrategies.hasOwnProperty('kpis')) {
    return (
      <Error />
    );
  }
  return (
    <div className="GoalsAndStrategies">
      <h1 className="page-title">Goals & Strategies</h1>
      <div className="goals-grid">
        <div className="component-grid-item">
          <Goals goals={goalsAndStrategies['overarching_goals']} goalsType={"overarching"} />
        </div>
        <div className="component-grid-item">
          <Goals goals={goalsAndStrategies['market_accel_goals']} goalsType={"market"} />
        </div>
      </div>
      <CoreStrategies coreStrategies={goalsAndStrategies['core_strategies']} />
      <KeyPerformanceIndicators kpis={goalsAndStrategies['kpis']} />
    </div>
  );
}

export default GoalsAndStrategies;
