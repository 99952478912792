import React from "react";
import "./SuperAdminPageSearch.css";

function SuperAdminPageSearch({ setSearch }) {
  return (
    <div className="super-admin-page-search-container">
      <input
        className="super-admin-page-search-input"
        type="text"
        placeholder="Search..."
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );
}

export default SuperAdminPageSearch;
