import { useState } from "react";
import bioLogo from "../images/bioLogo.svg";
import motivationsLogo from "../images/motivationsLogo.svg";
import frustrationsLogo from "../images/frustrationsLogo.svg";
import moreAboutDropdown from "../images/kpiDropdownIcon.svg";
import "./MoreAbout.css";

function MoreAbout(props) {
  let persona = props.persona;
  let bio = persona.bio.map((item, i) => <li key={i}>{item}</li>);
  let motivations = persona.motivations.map((item, i) => (
    <li key={i}>{item}</li>
  ));
  let frustrations = persona.frustrations.map((item, i) => (
    <li key={i}>{item}</li>
  ));

  const [moreAboutDropdownOpen, setmoreAboutDropdownOpen] = useState({
    bio: false,
    motivations: false,
    frustrations: false,
  });

  const moreAboutToggle = (key) => {
    setmoreAboutDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  return (
    <div className="MoreAbout">
      <h2>
        More About {persona.name} - {persona.title}
      </h2>
      <div className="more-about">
        <div className="Bio">
          <div className="moreabout-image">
            {" "}
            <img src={bioLogo} className="Bio-Logo" alt="Bio logo" />{" "}
          </div>
          <div className="moreabout-text">
            {" "}
            <h3>Bio</h3> <ul>{bio}</ul>{" "}
          </div>
        </div>
        <div className="Motivations">
          <div className="moreabout-image">
            {" "}
            <img
              src={motivationsLogo}
              className="Motivations-Logo"
              alt="Motivations logo"
            />{" "}
          </div>
          <div className="moreabout-text">
            {" "}
            <h3>Motivations</h3> <ul>{motivations}</ul>{" "}
          </div>
        </div>
        <div className="Frustrations">
          <div className="moreabout-image">
            {" "}
            <img
              src={frustrationsLogo}
              className="Frustrations-Logo"
              alt="Frustrations logo"
            />{" "}
          </div>
          <div className="moreabout-text">
            {" "}
            <h3>Frustrations</h3> <ul>{frustrations}</ul>{" "}
          </div>
        </div>
      </div>
      <div className="more-about-mobile">
        <div className="Bio-mobile" onClick={() => moreAboutToggle("bio")}>
          <div className="more-about-content-left">
            <img src={bioLogo} className="Bio-Logo" alt="Bio logo" />{" "}
            <h3>Bio</h3>
          </div>
          <img
            src={moreAboutDropdown}
            className={`more-about-dropdown-icon${moreAboutDropdownOpen.bio ? " flipped" : ""}`}
            alt="More About Dropdown Icon"
          />
        </div>
        <div
          className={`bio-mobile-list ${
            moreAboutDropdownOpen.bio ? "active" : ""
          }`}
        >
          <ul>{bio}</ul>
        </div>
        <div
          className="Motivations-mobile"
          onClick={() => moreAboutToggle("motivations")}
        >
          <div className="more-about-content-left">
            <img
              src={motivationsLogo}
              className="Motivations-Logo"
              alt="Motivations logo"
            />{" "}
            <h3>Motivations</h3>
          </div>
          <img
            src={moreAboutDropdown}
            className={`more-about-dropdown-icon${moreAboutDropdownOpen.motivations ? " flipped" : ""}`}
            alt="More About Dropdown Icon"
          />
        </div>
        <div
          className={`motivations-mobile-list ${
            moreAboutDropdownOpen.motivations ? "active" : ""
          }`}
        >
          <ul>{motivations}</ul>
        </div>
        <div
          className="Frustrations-mobile"
          onClick={() => moreAboutToggle("frustrations")}
        >
          <div className="more-about-content-left">
            <img
              src={frustrationsLogo}
              className="Frustrations-Logo"
              alt="Frustrations logo"
            />

            <h3>Frustrations</h3>
          </div>
          <img
            src={moreAboutDropdown}
            className={`more-about-dropdown-icon${moreAboutDropdownOpen.frustrations ? " flipped" : ""}`}
            alt="More About Dropdown Icon"
          />
        </div>
        <div
          className={`frustrations-mobile-list ${
            moreAboutDropdownOpen.frustrations ? "active" : ""
          }`}
        >
          <ul>{frustrations}</ul>
        </div>
      </div>
    </div>
  );
}

export default MoreAbout;
