import React, { useContext, useState } from "react";
import { CornerpieceContext } from "../context/CornerpieceContext";
import { db } from "../firebase/firebaseConfig";
import { set, ref } from "firebase/database";
import "./AdminPageAddUsers.css";

// TODO: commonize with LoginPage.
const sanitizeEmail = (email) => {
  return email.replace('@', '%40').replace('.', '%2E');
}

function AdminPageAddUsers({ showAddForm, handleCloseForm }) {
  const cornerpiece = useContext(CornerpieceContext);
  const [newRole, setNewRole] = useState("member");
  const [newEmail, setNewEmail] = useState("");

  const handleSubmitNewUser = async (e) => {
    e.preventDefault();
    try {
      const newCpUserRef = ref(db, `/${cornerpiece}/user_access/${sanitizeEmail(newEmail)}`);
      if (newRole === 'admin' || newRole === 'member') {
        await set(newCpUserRef, newRole);
      }
      setNewEmail("");
      setNewRole("member");
      handleCloseForm();
    } catch (error) {
      console.error("Failed to add new user: ", error);
    }
  };

  return (
    <div className="admin-page-add-container">
      {showAddForm && (
        <div className="admin-page-add-form-container">
          <form className="admin-page-add-form" onSubmit={handleSubmitNewUser}>
            <div className="add-user-info-container">
              <input
                type="text"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                placeholder="User Email"
                required
              />
              <select value={newRole} onChange={e => setNewRole(e.target.value)}>
                <option value="member">Member</option>
                <option value="admin">Admin</option>
              </select>
              <button className="admin-add-btn submit-user-btn" type="submit">
                Add
              </button>
              <button className="admin-add-btn close-user-btn" onClick={handleCloseForm}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default AdminPageAddUsers;
