import React, { createContext, useState, useEffect } from "react";
import { db } from "../firebase/firebaseConfig";
import { get, ref } from "firebase/database";

export const SuperAdminContext = createContext();

export const SuperAdminProvider = (props) => {
  const [superAdmins, setSuperAdmins] = useState([]);

  useEffect(() => {
    const fetchSuperAdmins = async () => {
      const superAdminRef = ref(db, "superAdmins"); 
      const snapshot = await get(superAdminRef);

      if (snapshot.exists()) {
        const newSuperAdmins = snapshot.val();
        setSuperAdmins(Object.keys(newSuperAdmins));
      }
    };

    fetchSuperAdmins();
  }, []);

  return (
    <SuperAdminContext.Provider value={superAdmins}>
      {props.children}
    </SuperAdminContext.Provider>
  );
};
