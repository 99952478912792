import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/firebaseConfig";
import { onValue, ref, off, remove } from "firebase/database";
import SuperAdminPageEdit from "./SuperAdminPageEdit";
import editCornerpiece from "../images/editCornerpiece.svg";
import deleteCornerpiece from "../images/deleteCornerpiece.svg";
import "./SuperAdminPageViewAll.css";

function SuperAdminPageViewAll({ search = "" }) {
  const [cornerpieces, setCornerpieces] = useState(null);
  const [activeEdit, setActiveEdit] = useState(null);
  const navigate = useNavigate();

  const handleOpenAdmin = (cornerpieceURL) => {
    navigate(`/${cornerpieceURL}/admin`);
  };

  const handleCornerpieceOpen = (cornerpieceURL) => {
    navigate(`/${cornerpieceURL}`);
  };

  useEffect(() => {
    const cornerpiecesRef = ref(db, "cornerpieces");
    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        setCornerpieces(snapshot.val());
      } else {
        console.log("No data available");
      }
    };
    onValue(cornerpiecesRef, handleDataChange, { onlyOnce: false });
    return () => off(cornerpiecesRef, "value", handleDataChange);
  }, []);

  const handleDeleteClick = async (key) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this cornerpiece?"
    );
    if (confirmed) {
      const cornerpieceRef = ref(db, `cornerpieces/${key}`);
      const cornerpieceIdRef = ref(db, `/${key}`);
      try {
        await remove(cornerpieceRef);
        await remove(cornerpieceIdRef);
     
      } catch (error) {
        console.error("Error removing cornerpiece: ", error);
      } 
    }
  };

  return (
    <div className="super-admin-page-view-container">
      {cornerpieces &&
        Object.entries(cornerpieces)
          .filter(([key, value]) =>
            value.name.toLowerCase().includes(search.toLowerCase())
          )
          .map(([key, value]) => (
            <div className="super-admin-page-view-cornerpiece" key={key}>
              <div className="cornerpiece-content">
                <div>
                  <h2 className="cornerpiece-name" onClick={() => handleCornerpieceOpen(value.url)}>
                    {value.name}
                  </h2>
                  <p className="cornerpiece-client-name">{value.client_name} | cornerpiece.app/{value.url}</p>
                </div>
                <div className="button-container">
                  <button
                    className="open-button"
                    onClick={() => handleOpenAdmin(value.url)}
                  >
                    ADMIN
                  </button>
                  <button
                    className="edit-button"
                    onClick={() => {
                      if (activeEdit === key) {
                        setActiveEdit(null)
                      } else {
                        setActiveEdit(key)
                      }}}
                  >
                    <img
                      className="edit-image"
                      src={editCornerpiece}
                      alt="editCornerpiece"
                    />
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteClick(key)}
                  >
                    <img
                      className="delete-image"
                      src={deleteCornerpiece}
                      alt="deleteCornerpiece"
                    />
                  </button>
                </div>
              </div>
              {activeEdit === key && (
                <SuperAdminPageEdit
                  cornerpieceKey={key}
                  cornerpieceDetails={value}
                  handleClose={() => setActiveEdit(null)}
                />
              )}
            </div>
          ))}
    </div>
  );
}

export default SuperAdminPageViewAll;
