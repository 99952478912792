import React, { useState } from "react";
import { db } from "../firebase/firebaseConfig";
import { set, ref, push } from "firebase/database";
import { getAuth, currentUser } from "firebase/auth";
import "./SuperAdminPageAdd.css";

function SuperAdminPageAdd({ showAddForm, handleCloseForm }) {
  const auth = getAuth();
  const [cornerpiece, setCornerpiece] = useState("");
  const [cornerpieceURL, setCornerpieceURL] = useState("");
  const [clientName, setClientName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const newCornerpieceRef = push(ref(db, "cornerpieces"));
    set(newCornerpieceRef, {
      name: cornerpiece,
      url: cornerpieceURL,
      client_name: clientName,
    });

    setCornerpiece("");
    setCornerpieceURL("");
    setClientName("");
  };

  return (
    <div className="super-admin-page-add-container">
      {showAddForm && (
        <div className="super-admin-page-add-form-container">
          <form className="super-admin-page-add-form" onSubmit={handleSubmit}>
            <div className="super-admin-input-container">
              <input
                type="text"
                value={cornerpiece}
                onChange={(e) => setCornerpiece(e.target.value)}
                placeholder="Cornerpiece name"
                required
              />
              <input
                type="text"
                value={cornerpieceURL}
                onChange={(e) => setCornerpieceURL(e.target.value)}
                placeholder="Cornerpiece URL"
                required
              />
              <input
                type="text"
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder="Client name"
                required
              />
            </div>
            <div className="super-admin-page-add-form-button-container">
              <button className="superadmin-add-btn submit-btn" type="submit">
                Add
              </button>
              <button
                className="superadmin-add-btn close-btn"
                onClick={handleCloseForm}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default SuperAdminPageAdd;
