import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { get, getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyClml-lTkEi28uJ9V38vdfucSE_A-hTKV8",
  authDomain: "cornerpiece-bb404.firebaseapp.com",
  databaseURL: "https://cornerpiece-bb404-default-rtdb.firebaseio.com",
  projectId: "cornerpiece-bb404",
  storageBucket: "cornerpiece-bb404.appspot.com",
  messagingSenderId: "794434255736",
  appId: "1:794434255736:web:43e5281bbb69f296ca668d",
  measurementId: "G-3RDG2DDR7B",
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getDatabase(app);
