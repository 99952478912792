import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import { SuperAdminContext } from "../context/SuperAdminContext";
import navbarToggler from "../images/navbarToggler.svg";
import navbarAccount from "../images/navbarAccount.svg";
import { useLocation } from "react-router-dom";
import { db } from "../firebase/firebaseConfig";
import { get, ref } from "firebase/database";
import { auth } from "../firebase/firebaseConfig";
import { useNavigate } from "react-router-dom";
import "./NavBar.css";
import { CornerpieceContext } from "../context/CornerpieceContext";

function NavBar({selectedPageSetter}) {
  const cornerpieceUrl = useContext(CornerpieceContext);
  const { user } = useContext(UserContext);
  const superAdmins = useContext(SuperAdminContext);
  const [isSuperAdmin, setIsSuperAdmin] = useState(null);
  const [isAdmin, setIsAdmin] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchSuperAdminRole = async () => {
      try {
        setIsSuperAdmin(superAdmins.includes(user.user_id));
      } catch (error) {
        setIsSuperAdmin(false);
      }
    };
    fetchSuperAdminRole();
  }, [user, superAdmins]);

  useEffect(() => {
    const fetchCornerpieceRole = async () => {
      try {
        const userAccessSnapshot = await get(ref(db, `${cornerpieceUrl}/user_access/${user.email}`));
        if (userAccessSnapshot.exists()) {
          const userAccessData = userAccessSnapshot.val();
          setIsAdmin(userAccessData === "admin");
        } else {
          // TODO: Error!
          setIsAdmin(false);
        }
      } catch (error) {
        console.error("Failed to fetch Cornerpiece role", error);
        setIsAdmin(false);
      }
    };
    fetchCornerpieceRole();
  }, [location, user, setIsAdmin, cornerpieceUrl]);

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  const [childState, setChildState] = useState(0);

  const [accountDropdown, setAccountDropdown] = useState(false);

  const onNavClick = e => {
    setChildState(e.target.value);
    selectedPageSetter(e.target.value);
  };

  const onAccountClick = () => setAccountDropdown(!accountDropdown);

  const navigate = useNavigate();

  const onDropdownButtonClick = e => {
    const urlSplit = location.pathname.split("/");
    const cornerpieceUrl = urlSplit[1];
    navigate(`/${cornerpieceUrl}/${e.target.value}`);
  }

  const handleLogout = () => {
    auth
      .signOut()
      .then(() => {
        console.log("successfully logged out");
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="navbar-container">
      <div className="homepage-navbar">
        <button
          onClick={toggleNavbar}
          className={`navbar-toggle-button ${
            collapsed ? "" : "navbar-toggle-button-open"
          }`}
        >
          <img
            src={navbarToggler}
            className="navbar-toggle"
            alt="navbar-toggler"
          />
        </button>
        <div className="navbar-right-content">
          <button onClick={onAccountClick} className="navbar-account-button">
            <img
              src={navbarAccount}
              className="navbar-account-img"
              alt="navbar-account"
            />
          </button>
          {accountDropdown && 
            <div className="navbar-account-dropdown">
              <button className="navbar-account-dropdown-button" onClick={e => { navigate(`/`); }}>Home</button>
              {(isAdmin || isSuperAdmin) && <button  className="navbar-account-dropdown-button" value="admin" onClick={onDropdownButtonClick}>Admin</button>}
              <button className="navbar-account-dropdown-button" onClick={handleLogout}>Sign Out</button>
            </div>
          }
        </div>
      </div>
      {collapsed ? null : (
        <div className="navbar-open">
          <div className="navbar-links-container">
            <div className="navbar-links">
              {/* TODO: Do this right. */}
              <button onClick={onNavClick} value="0" className={childState == 0 ? "navbar-link-selected" : "navbar-link"}>
                Core Components
              </button>
              <button onClick={onNavClick} value="1" className={childState == 1 ? "navbar-link-selected" : "navbar-link"}>
                Targeted Messaging
              </button>
              <button onClick={onNavClick} value="2"  className={childState == 2 ? "navbar-link-selected" : "navbar-link"}>
                Goals & Strategies
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
