import visionLogo from "../images/visionlogo.svg";

function Vision(props) {
  let title = "Vision";
  // let vision =
  //   "Our mission is to help companies articulate and visualize their value to grow their business.";
  let vision = props.vision;
  return (
    <div className="Vision">
      <div className="core-components-grid-logo-container">
        <img src={visionLogo} className="core-components-grid-logo" alt="Vision logo" />
      </div>
      <div className="core-components-grid-info">
        <h3>{title}</h3>
        <p>{vision}</p>
      </div>
    </div>
  );
}

export default Vision;
