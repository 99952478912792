import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
const CornerpieceContext = createContext(null);

const CornerpieceProvider = ({ children }) => {
  const [cornerpieceUrl, setCornerpieceUrl] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      const db = getDatabase();
      const urlSplit = location.pathname.split("/");
      if (urlSplit.length < 2) {
        console.log("No cornerpiece specified.");
      }
      setCornerpieceUrl(urlSplit[1]);
    };

    fetchData();
  }, [location, setCornerpieceUrl]);

  return (
    <CornerpieceContext.Provider value={cornerpieceUrl}>
      {children}
    </CornerpieceContext.Provider>
  );
};

export { CornerpieceContext, CornerpieceProvider };
