import { useState } from "react";
import "./KeyPerformanceIndicators.css";
import keyperformanceindicatorslogo from "../images/keyperformanceindicatorslogo.svg";
import keyPerformanceDropdown from "../images/kpiDropdownIcon.svg";

function KeyPerformanceIndicators(props) {
  const [kpiDropdownOpen, setkpiDropdownOpen] = useState({});

  const toggle = (key) => {
    setkpiDropdownOpen((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  let title = "Key Performance Indicators";
  const data = props.kpis;
  return (
    <div className="KeyPerformanceIndicators">
      <div className="kpi-container">
        <div className="kpi-image">
          <img
            src={keyperformanceindicatorslogo}
            className="Key-Performance-Indicators-Logo"
            alt="Key Performance Indicators logo"
          />
        </div>
        <h1>{title}</h1>
      </div>
      <table className="kpi-table">
        <tbody>
          {data.map((val, key) => {
            return (
              <tr key={key}>
                <td className="kpi">{val.kpi}</td>
                <td className="objective">{val.objective}</td>
                <td>{val.date}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {data.map((val, key) => (
        <div key={key} className="kpi-row-mobile">
          <div className="kpi-and-toggle" onClick={() => toggle(key)}>
            <div className="kpi-mobile">{val.kpi}</div>
            <img
              src={keyPerformanceDropdown}
              className={`key-performance-dropdown-icon ${
                kpiDropdownOpen[key] ? "flipped" : ""
              }`}
              alt="Key Performance Dropdown Icon"
            />
          </div>
          <div
            className={`kpi-details-mobile ${
              kpiDropdownOpen[key] ? "active" : ""
            }`}
          >
            <span className="objective-mobile">{val.objective}</span>
            <span className="date-mobile">{val.date}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default KeyPerformanceIndicators;
