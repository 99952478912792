import approachLogo from "../images/approachLogo.svg";

function Approach(props) {
  let title = "Approach";
  // let approach =
  //   "Our flagship product, the Cornerpiece™, combines principles from strategy, marketing and user experience design, to help our clients find clarity, alignment, consistency, and a benchmark for decision-making.";
  let approach = props.approach;
  return (
    <div className="Approach">
      <div className="core-components-grid-logo-container">
        <img src={approachLogo} className="core-components-grid-logo" alt="Approach logo" />
      </div>
      <div className="core-components-grid-info">
        <h3>{title}</h3>
        <p>{approach}</p>
      </div>
    </div>
  );
}

export default Approach;
