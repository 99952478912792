import KeyValueProps from "./KeyValueProps";
import Mission from "./Mission";
import Values from "./Values";
import Vision from "./Vision";
import Approach from "./Approach";
import AboutUs from "./AboutUs";
import Loading from "./Loading";
import Error from "./Error";
import "./CoreComponents.css";
import { useContext, useEffect, useState } from "react";
import { CornerpieceContext } from "../context/CornerpieceContext";
import { off, onValue, ref } from "firebase/database";
import { db } from "../firebase/firebaseConfig";

function CoreComponents() {
  const cornerpieceUrl = useContext(CornerpieceContext);
  const [coreComponents, setCoreComponents] = useState(null);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    console.log(cornerpieceUrl);
    const coreComponentsRef = ref(db, `${cornerpieceUrl}/core_components`);
    const handleDataChange = (snapshot) => {
      if (snapshot.exists()) {
        setCoreComponents(snapshot.val());
      } else {
        console.log("No data available");
      }
      setLoading(false);
    };
    onValue(coreComponentsRef, handleDataChange, { onlyOnce: false });
    return () => off(coreComponentsRef, "value", handleDataChange);
  }, [cornerpieceUrl, setCoreComponents]);

  if (loading) {
    return (
      <Loading />
    );
  }
  if (!coreComponents ||
      !coreComponents.hasOwnProperty('key_differentiators') ||
      !coreComponents.hasOwnProperty('mission') ||
      !coreComponents.hasOwnProperty('vision') ||
      !coreComponents.hasOwnProperty('values') ||
      !coreComponents.hasOwnProperty('approach') ||
      !coreComponents.hasOwnProperty('about_us_brief') ||
      !coreComponents.hasOwnProperty('about_us')) {
    return (
      <Error />
    );
  }
  return (
    <div className="CoreComponents">
      <h1 className="page-title">Core Components</h1>
      <KeyValueProps keyDifferentiators={coreComponents['key_differentiators']} />
      <div className="core-components-grid">
        <div className="component-grid-item">
          <Mission mission={coreComponents['mission']} />
        </div>
        <div className="component-grid-item">
          <Vision vision={coreComponents['vision']}/>
        </div>
        <div className="component-grid-item">
          <Values values={coreComponents['values']}/>
        </div>
        <div className="component-grid-item">
          <Approach approach={coreComponents['approach']}/>
        </div>
      </div>
      <AboutUs aboutUsBrief={coreComponents['about_us_brief']} aboutUs={coreComponents['about_us']} />
    </div>
  );
}

export default CoreComponents;
