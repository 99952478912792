import "./AboutUs.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Accordion, useAccordionButton } from "react-bootstrap";
import { useState } from "react";
import aboutUsDropdownIcon from "../images/aboutUsDropdown.svg";

function AboutUs(props) {
  const [activeId, setActiveId] = useState('expanded');
  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  function AccordionToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      toggleActive(eventKey);
    });
    return (
      <button
        type="button"
        onClick={decoratedOnClick}
      >
        {children}
      </button>
    );
  }

  let etitle = "About Us (Expanded)";
  let btitle = "About Us (Brief)";
  let expanded = props.aboutUs.replaceAll("\\n", "\n");
  let brief = props.aboutUsBrief.replaceAll("\\n", "\n");
  return (
    <div className="about-us">
      <div className="about-us-tabs-container">
        <Tabs defaultActiveKey="expanded" id="fill-tab-example">
          <Tab eventKey="brief" title={btitle}>
            {brief}
          </Tab>
          <Tab eventKey="expanded" title={etitle}>
            {expanded}
          </Tab>
        </Tabs>
      </div>
      <div className="about-us-accordion-container">
        <Accordion defaultActiveKey={activeId}>
          <div className={activeId === 'brief' ? "about-us-item-container about-us-showing" : "about-us-item-container"}>
            <div className="about-us-title-container">
              <AccordionToggle eventKey="brief">
                <span>About Us (Brief)</span>
                <img
                  className="about-us-dropdown-icon"
                  src={aboutUsDropdownIcon}
                  alt="about us dropdown icon"/>
              </AccordionToggle>
            </div>
            <Accordion.Collapse eventKey="brief">
              <div>{brief}</div>
            </Accordion.Collapse>
          </div>
          <div className={activeId === 'expanded' ? "about-us-item-container about-us-showing" : "about-us-item-container"}>
            <div className="about-us-title-container">
              <AccordionToggle eventKey="expanded">
                <span>About Us (Expanded)</span>
                <img
                  className="about-us-dropdown-icon"
                  src={aboutUsDropdownIcon}
                  alt="about us dropdown icon"/>
              </AccordionToggle>
            </div>
            <Accordion.Collapse eventKey="expanded">
              <div>{expanded}</div>
            </Accordion.Collapse>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default AboutUs;
