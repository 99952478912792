import "./Error.css";

function ErrorPage() {
  return (
    <div className="Error">
      <div className="error-container">
        <h1 className="error-title">Oops!</h1>
        <h2 className="error-subtitle">Something went wrong.</h2>
      </div>
    </div>
  );
}

export default ErrorPage;
