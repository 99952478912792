import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { SuperAdminContext } from "../context/SuperAdminContext";
import { get, ref } from "firebase/database";
import { auth, db } from "../firebase/firebaseConfig";

const AdminRoute = ({ children }) => {
  const { user } = useContext(UserContext);
  const superAdmins = useContext(SuperAdminContext);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [adminLoading, setAdminLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const url = location.pathname.split("/")[1];

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    if (superAdmins && user) {
      let newIsSuperAdmin = superAdmins.includes(user.user_id);
      setIsSuperAdmin(newIsSuperAdmin);
      if (!user) {
        console.log("No user object found");
        auth.signOut().then(() => {
          navigate("/login");
        })
        .catch((err) => {
          console.log(err);
          navigate("/login");
        });
        return;
      }
      const fetchUserData = async () => {
        const userAccessRef = ref(
          db,
          `/${url}/user_access/${user.email}`
        );
        const userAccessSnapshot = await get(userAccessRef);
        if (userAccessSnapshot.exists()) {
          const userData = userAccessSnapshot.val();
          if (userData === "admin") {
            setIsAdmin(true);
          }
        }
        if (newIsSuperAdmin) {
          setIsAdmin(true);
        }
        setAdminLoading(false);
      };
      fetchUserData();
    }
  }, [url, user, navigate, superAdmins, isSuperAdmin, setAdminLoading, setIsSuperAdmin, isAdmin, setIsAdmin]);

  if (adminLoading) {
    return <div>Loading...</div>;
  }

  return isAdmin ? children : <Navigate to="/" replace />;
};

export default AdminRoute;
