import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/firebaseConfig";
import { ref, get } from "firebase/database";
import "./SelectCornerpiece.css";
import { UserContext } from "../context/UserContext";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebaseConfig";
import { SuperAdminContext } from "../context/SuperAdminContext";

function SelectCornerpiece() {
  const [userCornerpieces, setUserCornerpieces] = useState([]);
  const { user } = useContext(UserContext);
  const superAdmins = useContext(SuperAdminContext);
  const [authUser] = useAuthState(auth);
  const navigate = useNavigate();

  const handleCornerpieceOpen = (cornerpieceURL) => {
    navigate(`/${cornerpieceURL}`);
  };

  useEffect(() => {
    if (!authUser) {
      navigate("/login");
    }
    const collectAllUsersCornerpieces = async () => {
      const cornerpiecesRef = ref(db, `/cornerpieces`);
      const cornerpiecesSnapshot = await get(cornerpiecesRef);
      var newUserCornerpieces = [];
      if (cornerpiecesSnapshot.exists()) {
        const cornerpieces = cornerpiecesSnapshot.val();
        const keys = Object.keys(cornerpieces);
        for (let i in keys) {
          let cp = cornerpieces[keys[i]];
          const allowedUsersRef = ref(db, `${cp.url}/user_access/${user.email}`);
          const snapshot = await get(allowedUsersRef);
          if (superAdmins.includes(user.user_id) || snapshot.exists()) {
            // User is at least a member.
            newUserCornerpieces.push(cp);
          }
        }
      }
      setUserCornerpieces(newUserCornerpieces);
      if (newUserCornerpieces.length === 1) {
        let onlyCp = newUserCornerpieces[0];
        navigate(`${onlyCp.url}`);
      }
    };
    collectAllUsersCornerpieces();
  }, [userCornerpieces, navigate, user, authUser]);

  return (
    <div className="select-cornerpiece">
      <div className="select-cornerpiece-container">
        <h1>Cornerpieces</h1>
        <div className="select-cornerpiece-view-container">
          {userCornerpieces &&
            Object.entries(userCornerpieces)
              .map(([key, value]) => (
                <div className="select-cornerpiece-view-cornerpiece" key={key}>
                  <h2 className="select-cornerpiece-cornerpiece-name" onClick={() => handleCornerpieceOpen(value.url)}>
                    {value.name}
                  </h2>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
}

export default SelectCornerpiece;
